import React, { useState } from 'react'
import Layout from '../components/Layout.js'
import { Form, Loader } from '@patomation/react-ui-components'
import { navigate } from 'gatsby'
import useMutation from '../hooks/useMutation'

//TODO make stars input work....
const ADD_REVIEW = `mutation	{
  addReview(input: {
    name: "$name"
    email: "$email"
    message: "$message"
  }){
    name
    email
    message
  }
}
`

const Review = () => {

  const [ complete, setComplete ] = useState(false)

  const [ submit, {loading} ] = useMutation(
    ADD_REVIEW, {
      onCompleted: (result) => {
        setComplete(true)
      }
    }
  )

  if(loading) return (
    <Layout>
      <Loader
        message='Submitting Please Wait'
        progressBar={true}
        progressBarColor='#86AC41'
        progressBarTime={10000}
        complete={complete}
        spinner={false}
        onComplete={() => {
          navigate('/review-success')
        }}/>
    </Layout>
  )


  return (
   <Layout>
    <section>

    <style>{`
      .starrating {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
      }

      .starrating > input {
        display: none;
      }

      .starrating > label:before {
        margin: 2px;
        font-size: 3rem;
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        font-weight: 900;
        content: "\\f005";
      }

      .starrating > label
      { color: #324851; }

      .starrating > input:checked ~ label
      { color: #86AC41; }

      .starrating > input:hover ~ label
      { color: #86AC41; }
    `}</style>

    <Form
      labels={true}
      onSubmit={(values) => {
        submit({variables: {
          name: values.name,
          email: values.email,
          message: values.message,
          // stars: values.stars
         } })
      }}
      fields={[
        {
          name: 'name',
          label: 'Name',
          required: true
        },
        {
          name: 'email',
          type: 'email',
          label: 'Email',
          placeholder: 'Email address',
          required: true,
          validation: 'email'
        },
        {
          name: 'message',
          type: 'textarea',
          label: 'Message',
          placeholder: 'Enter message',
          required: true,
          validation: 'length>5' //TODO make this work
        },
      ]}
      buttonStyle={{
        background:'#86AC41', //green
        color:'#324851', //dark blue green
        width: 'auto'
      }}
      inputStyle={{
        textAlign: 'left'
      }}>

      <div className='starrating'>
        <input type="radio" id="star5" name="rating" value="5" /><label htmlFor="star5" title="5 star"></label>
        <input type="radio" id="star4" name="rating" value="4" /><label htmlFor="star4" title="4 star"></label>
        <input type="radio" id="star3" name="rating" value="3" /><label htmlFor="star3" title="3 star"></label>
        <input type="radio" id="star2" name="rating" value="2" /><label htmlFor="star2" title="2 star"></label>
        <input type="radio" id="star1" name="rating" value="1" /><label htmlFor="star1" title="1 star"></label>
      </div>

      </Form>


    </section>
   </Layout>
 )

}

export default Review
